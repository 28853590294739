<template>
  <div class="feature_container">
    <div @click="goBack" class="back_btn">返回</div>
    <div class="title">{{ title }}</div>
    <div class="number" v-if="visitLoaded">浏览量：{{ showNumber }}</div>
    <!-- <div class="intro">{{intro}}</div>
    <img class="bg" src="@/assets/img/yajiang.jpg" />
    <div class="intro">{{content}}</div> -->
    <div class="intro" v-html="content"></div>
    <div class="divide"></div>
    <div
      class="stitle"
      style="float: left"
      v-if="preObj !== null && preObj !== {}"
      @click="goDetail(preObj.id)"
    >
      上一篇：{{ preObj.title }}
    </div>
    <div
      class="stitle"
      style="float: right"
      v-if="nextObj !== null && nextObj !== {}"
      @click="goDetail(nextObj.id)"
    >
      下一篇：{{ nextObj.title }}
    </div>
    <el-backtop style="width: 1rem; height: 2rem" :right="10" :bottom="50">
      <div style="height: 100%; width: 100%">
        <img style="width: 100%; height: 100%" src="@/assets/img/toTop.png" />
        <div style="text-align: center" class="goTop">回顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'village',
  data() {
    return {
      pageOption: {
        page: 1,
        size: 12,
        total: 38
      },
      id: 0,
      title: '',
      nextObj: {},
      preObj: {},
      preTitle: '',
      nextTitle: '',
      intro: '',
      content: '',
      showNumber: 0,
      visitLoaded: false
    }
  },
  methods: {
    // 跳转至详情页
    goDetail(val) {
      // window.open('/#/detail?id=' + val)
      this.$router.push({ path: '/detail', query: { id: val } })
    },
    goBack() {
      this.$router.go(-1)
    },
    getDetail() {
      const url = '/api/web_index/getDocumentsModel?id=' + this.id
      this.$axios.get(url).then((res) => {
        const a = res.data
        this.title = a.title
        this.content = a.content.replace(/#ffffff/g, 'transparent')
        this.content = this.content.replace(/<img/g, '<img class="bg"')
        this.nextObj = a.next
        this.preObj = a.prev
        // this.showNumber = a.showViewsNumber
        this.$axios
          .get('/api/v2/visit/stats/doc?type=web&id=' + this.id)
          .then((res) => {
            this.showNumber = res.data
            this.visitLoaded = true
          })
      })
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
    }
  },
  watch: {
    '$route.query.id'() {
      this.id = this.$route.query.id
      this.getDetail()
    }
  }
}
</script>

<style lang="scss">
// @import 'index.scss';
.feature_container {
  padding-bottom: 2rem;
  .el-backtop {
    background-color: transparent;
    box-shadow: none;
  }
  .back_btn {
    // margin: .3rem .5rem;
    margin-top: 0.8rem;
    font-size: 0.2rem;
    padding: 0.1rem 0.3rem;
    border-radius: 0.8rem;
    border: 0.01rem solid #3bb573;
    color: #3bb573;
    background: transparent;
    cursor: pointer;
    font-family: 'MicrosoftYaHei';
    width: 1.6rem;
    text-align: center;
    letter-spacing: 2px;
  }
  .title {
    margin: 0.44rem 0 0.6rem 0;
    text-align: center;
    font-size: 0.8rem;
    font-family: 'MicrosoftYaHei', 'MicrosoftYaHei-Bold';
    font-weight: 700;
    color: #333333;
    line-height: 0.8rem;
    letter-spacing: 2px;
  }
  .number {
    margin-bottom: 1.6rem;
    height: 16px;
    opacity: 1;
    font-size: 16px;
    font-family: 'MicrosoftYaHeiLight';
    text-align: center;
    color: #666666;
    line-height: 16px;
    letter-spacing: 1px;
  }
  .intro {
    // padding: 0 .5rem;
    color: #333;
    font-size: 0.36rem;
    font-family: 'SimHei';
    text-align: left;
    color: #333333;
    line-height: 0.6rem;
    letter-spacing: 2px;
    text-indent: 2em;
  }
  .bg {
    width: 100% !important;
    height: 66.6% !important;
    margin: 1rem 0;
    margin-left: -0.72rem;
  }
  .divide {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    margin: 1rem 0;
  }
  .stitle {
    font-size: 0.32rem;
    font-family: 'SimHei';
    color: #333333;
    margin-bottom: 0.4rem;
    cursor: pointer;
  }
  .el-backtop {
    background-color: transparent;
    box-shadow: none;
    .goTop {
      height: 14px;
      opacity: 1;
      font-size: 14px;
      font-family: 'MicrosoftYaHei';
      text-align: center;
      font-weight: 700;
      color: #333333;
      line-height: 14px;
    }
  }
}
</style>
